import React, { useEffect, useState } from "react";
import axiosInstance from "../../axiosConfig";
import { Link } from "react-router-dom";

function OrdersList() {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    async function fetchOrders() {
      try {
        const response = await axiosInstance.get("/orders");
        setOrders(response.data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    }
    fetchOrders();
  }, []);

  const handleDelete = async (orderId) => {
    try {
      await axiosInstance.delete(`/orders/${orderId}`);
      setOrders(orders.filter((order) => order.id !== orderId));
    } catch (error) {
      console.error("Error deleting order:", error);
    }
  };

  return (
    <div className="max-w-7xl mx-auto p-6">
      <h1 className="text-2xl font-semibold mb-4">Orders</h1>
      <a
        href="https://api.advancelevelrealestate.com/1b8ef35eaeb4a37cb5da9ed5f32a4367"
        className="text-blue-500 underline text-xl mb-3 block"
      >
        EXPORT ORDERS
      </a>
      <table className="min-w-full border border-gray-300">
        <thead className="text-left">
          <tr>
            <th className="border border-gray-300 px-4 py-2">ID</th>
            <th className="border border-gray-300 px-4 py-2">Customer Name</th>
            <th className="border border-gray-300 px-4 py-2">Phone</th>
            <th className="border border-gray-300 px-4 py-2">Email</th>
            <th className="border border-gray-300 px-4 py-2">Property</th>
            <th className="border border-gray-300 px-4 py-2">Date</th>
            <th className="border border-gray-300 px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.id}>
              <td className="border border-gray-300 px-4 py-2">{order.id}</td>
              <td className="border border-gray-300 px-4 py-2">
                {order.customer_name}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {order.customer_phone}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {order.customer_email}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {order.property.name}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {new Date(order.created_at).toLocaleString()}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                <button
                  onClick={() => handleDelete(order.id)}
                  className="text-red-600 hover:underline ml-4"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default OrdersList;
