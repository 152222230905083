import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import AuthProvider from "./Context/AuthContext";
import PropertyList from "./Pages/Properties/PropertyList";
import AddProperty from "./Pages/Properties/AddProperty";
import EditProperty from "./Pages/Properties/EditProperty";
import CategoryList from "./Pages/Categories/CategoryList";
import AddCategory from "./Pages/Categories/AddCategory";
import EditCategory from "./Pages/Categories/EditCategory";
import ProtectedRoute from "./Components/ProtectedRoute";
import Login from "./Pages/Login";
import PropertyImageManager from "./Pages/Properties/PropertyImageManager";
import Navbar from "./Components/Navbar";
import OrdersList from "./Pages/Orders/OrdersList";
import AdminContactRequests from "./Pages/ContactRequests";

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate to="/properties" />} />
          <Route
            path="/properties"
            element={
              <ProtectedRoute>
                <PropertyList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/properties/add"
            element={
              <ProtectedRoute>
                <AddProperty />
              </ProtectedRoute>
            }
          />
          <Route
            path="/properties/edit/:id"
            element={
              <ProtectedRoute>
                <EditProperty />
              </ProtectedRoute>
            }
          />
          <Route
            path="/properties/images/:id"
            element={
              <ProtectedRoute>
                <PropertyImageManager />
              </ProtectedRoute>
            }
          />
          <Route
            path="/categories"
            element={
              <ProtectedRoute>
                <CategoryList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/categories/add"
            element={
              <ProtectedRoute>
                <AddCategory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/categories/edit/:id"
            element={
              <ProtectedRoute>
                <EditCategory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders"
            element={
              <ProtectedRoute>
                <OrdersList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/contact-requests"
            element={
              <ProtectedRoute>
                <AdminContactRequests />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<p className="text-center text-4xl mt-[10rem]">PAGE NOT FOUND</p>} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
