import React, { createContext, useState, useEffect, useContext } from "react";
import axiosInstance from "../axiosConfig";

export const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);

  useEffect(() => {
    const verifyToken = async () => {
      if (token) {
        try {
          await axiosInstance.get("/check", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setIsAuthenticated(true);
        } catch (error) {
          console.error("Token verification failed:", error);
          localStorage.removeItem("token");
          setIsAuthenticated(false);
        }
      }
    };
    verifyToken();
  }, [token]);

  const login = async (username, password) => {
    try {
      const response = await axiosInstance.post("/login", { username, password });
      localStorage.setItem("token", response.data.token);
      setToken(response.data.token);
      setIsAuthenticated(true);
      window.location.href = "/"; // fixed the redirect
    } catch (error) {
      if (error.response && error.response.data.message === "Invalid credentials") {
        alert("Invalid Username or Password");
      } else {
        alert("SERVER ERROR CONTACT DEVELOPER");
      }
    }
  };


  const logout = () => {
    setToken(null);
    localStorage.removeItem("token");
    setIsAuthenticated(false);
  };

  return <AuthContext.Provider value={{ token, isAuthenticated, login, logout }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
