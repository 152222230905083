import React, { useContext, useState } from 'react';
import { AuthContext } from '../Context/AuthContext';

const Login = () => {
    const { login, isAuthenticated } = useContext(AuthContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault();
        login(username, password);
    };

    if (isAuthenticated) {
      return  window.location.href = '/'
    }

    return (
        <div className="h-screen flex items-center justify-center">
            
            <form onSubmit={handleSubmit} className="flex flex-col space-y-4 w-96 mx-auto bg-white rounded-lg shadow-lg p-4 border">
                <p className='text-center'>LOGIN</p>
                <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" required className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-200" />
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-200" />
                <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-200">Login</button>
            </form>
        </div>
    );
};

export default Login;
