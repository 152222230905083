import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../../axiosConfig";

const CategoryList = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    const response = await axiosInstance.get("/categories");
    setCategories(response.data);
  };

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h1 className="text-xl font-bold mb-4">Categories List</h1>
      <Link to="/categories/add" className="bg-blue-500 text-white px-4 py-2 rounded mb-4 inline-block">
        Add Category
      </Link>
      <table className="min-w-full bg-white border rounded-lg">
        <thead className="text-left">
          <tr className="bg-gray-200 text-gray-600">
            <th className="py-2 px-4 border-b">Category Name</th>
            <th className="py-2 px-4 border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category) => (
            <tr key={category.id} className="hover:bg-gray-100">
              <td className="py-2 px-4 border-b">{category.name}</td>
              <td className="py-2 px-4 border-b">
                <Link to={`/categories/edit/${category.id}`} className="text-blue-500 hover:underline">
                  Edit
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CategoryList;

