import React, { useEffect, useState } from "react";
import axiosInstance from "../../axiosConfig";

function AddProperty() {
  const [propertyData, setPropertyData] = useState({
    name: "",
    description: "",
    price: "",
    currency: "USD",
    status: "Ready To Move",
    number_of_rooms: "",
    number_of_bathrooms: "",
    area_in_sqft: "",
    location: "",
    location_ar: "",
    category_id: "",
  });
  const [responseMessage, setResponseMessage] = useState("");
  const [categories, setCategories] = useState([]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPropertyData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axiosInstance.post("/properties", propertyData);
      setResponseMessage(response.data.message);
      setPropertyData({
        name: "",
        description: "",
        price: "",
        currency: "USD",
        status: "Ready To Move",
        number_of_rooms: "",
        number_of_bathrooms: "",
        area_in_sqft: "",
        location: "",
        location_ar: "",
        category_id: "",
      });
    } catch (error) {
      console.error("Error adding property: ", error);
      setResponseMessage("Error adding property.");
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axiosInstance.get("/categories");
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error.message);
      }
    };

    fetchCategories();
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-2xl mx-auto">
        <form
          onSubmit={handleSubmit}
          className="bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4"
        >
          <h1 className="text-3xl font-bold text-gray-900 mb-6 text-center">
            Add Property
          </h1>

          <div className="mb-6">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Property Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={propertyData.name}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="PROJ NAME | PROJECT | CITY | COUNTRY"
            />
          </div>

          <div className="mb-6">
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Description
            </label>
            <textarea
              id="description"
              name="description"
              value={propertyData.description}
              onChange={handleChange}
              required
              rows="4"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Describe the property"
            ></textarea>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <label
                htmlFor="price"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Price
              </label>
              <input
                type="number"
                id="price"
                name="price"
                value={propertyData.price}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter price"
              />
            </div>
            <div>
              <label
                htmlFor="currency"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Currency
              </label>
              <select
                id="currency"
                name="currency"
                value={propertyData.currency}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="USD">USD</option>
                <option value="AED">AED</option>
                <option value="EGP">EGP</option>
                <option value="EUR">EUR</option>
              </select>
            </div>
          </div>

          <div className="mb-6">
            <label
              htmlFor="status"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Status
            </label>
            <select
              id="status"
              name="status"
              value={propertyData.status}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="Ready To Move">Ready To Move</option>
              <option value="Off-Plan">Off-Plan</option>
            </select>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
            <div>
              <label
                htmlFor="number_of_rooms"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Rooms
              </label>
              <input
                type="number"
                id="number_of_rooms"
                name="number_of_rooms"
                value={propertyData.number_of_rooms}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Number of rooms"
              />
            </div>
            <div>
              <label
                htmlFor="number_of_bathrooms"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Bathrooms
              </label>
              <input
                type="number"
                id="number_of_bathrooms"
                name="number_of_bathrooms"
                value={propertyData.number_of_bathrooms}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Number of bathrooms"
              />
            </div>
            <div>
              <label
                htmlFor="area_in_sqft"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Area (sq ft)
              </label>
              <input
                type="number"
                id="area_in_sqft"
                name="area_in_sqft"
                value={propertyData.area_in_sqft}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Area in sq ft"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Location (EN)
              </label>
              <input
                type="text"
                id="location"
                name="location"
                value={propertyData.location}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter location in English"
              />
            </div>
            <div>
              <label
                htmlFor="location_ar"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Location (AR)
              </label>
              <input
                type="text"
                id="location_ar"
                name="location_ar"
                value={propertyData.location_ar}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter location in Arabic"
              />
            </div>
          </div>

          <div className="mb-6">
            <label
              htmlFor="category_id"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Category
            </label>
            <select
              id="category_id"
              name="category_id"
              value={propertyData.category_id}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Select a category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>

          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out"
            >
              Add Property
            </button>
          </div>
        </form>

        {responseMessage && (
          <div
            className={`mt-4 p-4 rounded-md ${
              responseMessage.includes("Error")
                ? "bg-red-100 text-red-700"
                : "bg-green-100 text-green-700"
            }`}
          >
            {responseMessage}
          </div>
        )}
      </div>
    </div>
  );
}

export default AddProperty;
