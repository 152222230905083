import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosConfig";

const EditPropertyText = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    price: "",
    currency: "USD",
    status: "Ready To Move",
    number_of_rooms: "",
    number_of_bathrooms: "",
    area_in_sqft: "",
    location: "",
    location_ar: "",
    category_id: "",
  });

  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [propertyResponse, categoriesResponse] = await Promise.all([
          axiosInstance.get(`/properties/${id}`),
          axiosInstance.get("/categories"),
        ]);

        const propertyData = propertyResponse.data;
        setFormData({
          name: propertyData.name,
          description: propertyData.description,
          price: propertyData.price,
          currency: propertyData.currency,
          status: propertyData.status,
          number_of_rooms: propertyData.number_of_rooms,
          number_of_bathrooms: propertyData.number_of_bathrooms,
          area_in_sqft: propertyData.area_in_sqft,
          location: propertyData.location,
          location_ar: propertyData.location_ar,
          category_id: propertyData.category_id,
        });

        setCategories(categoriesResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setError("Failed to load property data. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setSuccessMessage("");

    try {
      await axiosInstance.put(`/properties/${id}/update-text`, formData);
      setSuccessMessage("Property text updated successfully!");
    } catch (error) {
      console.error("Error updating property text:", error.message);
      setError("Failed to update property. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  const inputClass =
    "mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm";

  return (
    <div className="max-w-5xl mx-auto p-5">
      {}
      <h1 className="text-3xl font-bold mb-6 text-gray-800">Edit Property Text</h1>

      {/* SUCCESS MSG */}
      {successMessage && (
        <div
          className="mt-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <strong className="font-bold">Success!</strong>
          <span className="block sm:inline"> {successMessage}</span>
        </div>
      )}

      {/* ERROR MSG */}
      {error && (
        <div className="max-w-5xl mx-auto p-5">
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <strong className="font-bold">Error!</strong>
            <span className="block sm:inline"> {error}</span>
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-6 bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col space-y-2">
            <label htmlFor="name" className="text-sm font-medium text-gray-700">
              Property Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className={inputClass}
            />
          </div>
          <div className="flex flex-col ">
            <label htmlFor="price" className="text-sm font-medium text-gray-700">
              Price
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                type="number"
                id="price"
                name="price"
                value={formData.price}
                onChange={handleChange}
                required
                className={`${inputClass} pr-20`}
                style={{ width: "80%" }}
              />
              <div className="absolute inset-y-0 right-0 flex items-center">
                <select
                  id="currency"
                  name="currency"
                  value={formData.currency}
                  onChange={handleChange}
                  className="focus:ring-blue-500 focus:border-blue-500 h-full py-0 pl-2 pr-7 border-transparent bg-gray-100 text-gray-500 sm:text-sm rounded-r-md"
                >
                  <option value="USD">USD</option>
                  <option value="AED">AED</option>
                  <option value="EGP">EGP</option>
                  <option value="EUR">EUR</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-2">
          <label htmlFor="description" className="text-sm font-medium text-gray-700">
            Description
          </label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
            rows="4"
            className={inputClass}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="flex flex-col space-y-2">
            <label htmlFor="status" className="text-sm font-medium text-gray-700">
              Status
            </label>
            <select
              id="status"
              name="status"
              value={formData.status}
              onChange={handleChange}
              required
              className={inputClass}
            >
              <option value="Ready To Move">Ready To Move</option>
              <option value="Off-Plan">Off-Plan</option>
            </select>
          </div>
          <div className="flex flex-col space-y-2">
            <label htmlFor="number_of_rooms" className="text-sm font-medium text-gray-700">
              Number of Rooms
            </label>
            <input
              type="number"
              id="number_of_rooms"
              name="number_of_rooms"
              value={formData.number_of_rooms}
              onChange={handleChange}
              required
              min="0"
              className={inputClass}
            />
          </div>
          <div className="flex flex-col space-y-2">
            <label htmlFor="number_of_bathrooms" className="text-sm font-medium text-gray-700">
              Number of Bathrooms
            </label>
            <input
              type="number"
              id="number_of_bathrooms"
              name="number_of_bathrooms"
              value={formData.number_of_bathrooms}
              onChange={handleChange}
              required
              min="0"
              className={inputClass}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col space-y-2">
            <label htmlFor="area_in_sqft" className="text-sm font-medium text-gray-700">
              Area in Sq Ft
            </label>
            <input
              type="number"
              id="area_in_sqft"
              name="area_in_sqft"
              value={formData.area_in_sqft}
              onChange={handleChange}
              required
              min="0"
              className={inputClass}
            />
          </div>

          <div className="flex flex-col space-y-2">
            <label htmlFor="category_id" className="text-sm font-medium text-gray-700">
              Category
            </label>
            <select
              id="category_id"
              name="category_id"
              value={formData.category_id}
              onChange={handleChange}
              required
              className={inputClass}
            >
              <option value="">Select Category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div>
            <label htmlFor="location" className="block text-sm font-medium text-gray-700 mb-2">
              Location (EN)
            </label>
            <input
              type="text"
              id="location"
              name="location"
              value={formData.location}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter location in English"
            />
          </div>
          <div>
            <label htmlFor="location_ar" className="block text-sm font-medium text-gray-700 mb-2">
              Location (AR)
            </label>
            <input
              type="text"
              id="location_ar"
              name="location_ar"
              value={formData.location_ar}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter location in Arabic"
            />
          </div>
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            disabled={isLoading}
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
          >
            {isLoading ? "Updating..." : "Update Property Text"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditPropertyText;
