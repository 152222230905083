import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../axiosConfig";

const EditCategory = () => {
  const { id } = useParams();
  const [category, setCategory] = useState({
    name: "",
  });

  useEffect(() => {
    const fetchCategory = async () => {
      const response = await axiosInstance.get(`/categories/${id}`);
      setCategory(response.data);
    };
    fetchCategory();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategory({ ...category, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await axiosInstance.put(`/categories/${id}`, category);
    // يمكن إعادة التوجيه بعد النجاح
  };

  return (
    <form onSubmit={handleSubmit} className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h1 className="text-xl font-bold mb-4">تعديل قسم</h1>
      <input
        type="text"
        name="name"
        placeholder="اسم القسم"
        value={category.name}
        onChange={handleChange}
        required
        className="border border-gray-300 p-2 mb-4 w-full rounded"
      />
      <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
        تحديث
      </button>
    </form>
  );
};

export default EditCategory;

