import React, { useState, useEffect } from "react";
import axiosInstance from "../../axiosConfig";
import { useParams } from "react-router-dom";
import { Upload, X } from "lucide-react";

function PropertyImageManagement() {
  const [file, setFile] = useState();
  const [galleryImages, setGalleryImages] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [responseMessage, setResponseMessage] = useState("");
  const [mainImage, setMainImage] = useState("");
  const { id } = useParams();

  useEffect(() => {
    fetchPropertyDetails();
  }, []);

  const fetchPropertyDetails = async () => {
    try {
      const response = await axiosInstance.get(`/properties/${id}`);
      const { main_image, image_gallery } = response.data;
      setMainImage(main_image);
      setGalleryImages(
        Array.isArray(JSON.parse(image_gallery)) ? JSON.parse(image_gallery) : []
      );
    } catch (error) {
      console.error("Error fetching property details: ", error);
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleGalleryChange = (event) => {
    setGalleryImages(Array.from(event.target.files));
  };

  const handleMainImageSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", file);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      onUploadProgress: function (progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadProgress(percentCompleted);
      },
    };

    axiosInstance
      .post(`/properties/${id}/upmi`, formData, config)
      .then((response) => {
        setResponseMessage(response.data.message);
        setMainImage(response.data.new_main_image);
        fetchPropertyDetails();
      })
      .catch((error) => {
        console.error("Error uploading file: ", error);
        setResponseMessage("Error uploading file.");
      });
  };

  const handleGallerySubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    for (let i = 0; i < galleryImages.length; i++) {
      formData.append("images[]", galleryImages[i]);
    }

    axiosInstance
      .post(`/properties/${id}/add-gallery-images`, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        setResponseMessage(response.data.message);
        fetchPropertyDetails();
      })
      .catch((error) => {
        console.error("Error adding gallery images: ", error);
        setResponseMessage("Error adding gallery images.");
      });
  };

  const handleDeleteImage = async (image) => {
    try {
      await axiosInstance.delete(`/properties/${id}/delete-gallery-image`, {
        data: { image },
      });
      fetchPropertyDetails();
      setResponseMessage("Image deleted successfully.");
    } catch (error) {
      console.error("Error deleting image: ", error);
      setResponseMessage("Error deleting image.");
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <form onSubmit={handleMainImageSubmit} className="mb-8">
        <h1 className="text-2xl font-bold mb-4">Upload Main Image</h1>
        <div className="flex items-center space-x-4">
          <input
            type="file"
            onChange={handleFileChange}
            name="file"
            className="block w-full text-sm text-slate-500
              file:mr-4 file:py-2 file:px-4
              file:rounded-full file:border-0
              file:text-sm file:font-semibold
              file:bg-violet-50 file:text-violet-700
              hover:file:bg-violet-100"
          />
          <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full transition duration-300 flex items-center">
            <Upload size={18} className="mr-2" />
            Upload
          </button>
        </div>
        {uploadProgress > 0 && (
          <div className="mt-4">
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div
                className="bg-blue-600 h-2.5 rounded-full"
                style={{ width: `${uploadProgress}%` }}
              ></div>
            </div>
            <span className="text-sm text-gray-600 mt-1">{uploadProgress}% uploaded</span>
          </div>
        )}
      </form>

      <h2 className="text-xl font-semibold mb-4">Main Image</h2>
      {mainImage && (
        <img
          src={`https://api.advancelevelrealestate.com/storage/${mainImage}`}
          alt="Main"
          className="mb-6 w-full max-w-md rounded-lg shadow-md"
        />
      )}

      <form onSubmit={handleGallerySubmit} className="mb-8">
        <h1 className="text-2xl font-bold mb-4">Upload Gallery Images</h1>
        <div className="flex items-center space-x-4">
          <input
            type="file"
            onChange={handleGalleryChange}
            multiple
            className="block w-full text-sm text-slate-500
              file:mr-4 file:py-2 file:px-4
              file:rounded-full file:border-0
              file:text-sm file:font-semibold
              file:bg-violet-50 file:text-violet-700
              hover:file:bg-violet-100"
          />
          <button type="submit" className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-full transition duration-300 flex items-center">
            <Upload size={18} className="mr-2" />
            Upload Gallery
          </button>
        </div>
      </form>

      <h2 className="text-xl font-semibold mb-4">Gallery</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {Array.isArray(galleryImages) &&
          galleryImages.map((image, index) => (
            <div key={index} className="relative group">
              <img
                src={`https://api.advancelevelrealestate.com/storage/${image}`}
                alt={`Gallery ${index}`}
                className="w-full h-48 object-cover rounded-lg shadow-md transition duration-300 group-hover:opacity-75"
              />
              <button
                onClick={() => handleDeleteImage(image)}
                className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full opacity-0 group-hover:opacity-100 transition duration-300"
              >
                <X size={18} />
              </button>
            </div>
          ))}
      </div>

      {responseMessage && (
        <div className="mt-6 p-4 bg-green-100 text-green-700 rounded-lg">
          {responseMessage}
        </div>
      )}
    </div>
  );
}

export default PropertyImageManagement;