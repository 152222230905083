import React, { useState } from "react";
import axiosInstance from "../../axiosConfig";

const AddCategory = () => {
  const [category, setCategory] = useState({
    name: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategory({ ...category, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await axiosInstance.post("/categories", category);
    // can redirect after success
  };

  return (
    <form onSubmit={handleSubmit} className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h1 className="text-xl font-bold mb-4">Add Category</h1>
      <input
        type="text"
        name="name"
        placeholder="Category Name"
        value={category.name}
        onChange={handleChange}
        required
        className="border border-gray-300 p-2 mb-4 w-full rounded"
      />
      <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
        Add
      </button>
    </form>
  );
};

export default AddCategory;

