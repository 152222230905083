import axios from "axios";

// إعداد Base URL
const axiosInstance = axios.create({
  baseURL: "https://api.advancelevelrealestate.com/api/admin", // استبدلها برابط API الخاص بك
});

// إضافة CSRF Token إلى رؤوس الطلبات
axiosInstance.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

// إضافة Token من LocalStorage
const token = localStorage.getItem("token");
if (token) {
  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}
axios.defaults.withCredentials = true;
// إعدادات CORS (إذا لزم الأمر)

export default axiosInstance;
