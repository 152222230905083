import React, { useEffect, useState } from "react";
import axiosInstance from "../axiosConfig";

const AdminContactRequests = () => {
  // حالة لتخزين الطلبات
  const [contactRequests, setContactRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  // جلب الطلبات من الـ API عند تحميل الصفحة
  useEffect(() => {
    const fetchContactRequests = async () => {
      try {
        const response = await axiosInstance.get("/contact-requests");
        setContactRequests(response.data);
      } catch (error) {
        console.error("Error fetching contact requests:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchContactRequests();
  }, []);

  // دالة لحذف طلب بناءً على الـ ID
  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this request?");
    if (!confirmDelete) return;

    try {
      await axiosInstance.delete(`/contact-requests/${id}`);
      // تحديث الطلبات بعد الحذف
      setContactRequests(contactRequests.filter((request) => request.id !== id));
    } catch (error) {
      console.error("Error deleting contact request:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Contact Requests</h1>
      <a
        href="https://api.advancelevelrealestate.com/fea0g5515awqrg54was630e5a05"
        className="text-blue-500 underline text-xl mb-3 block"
      >
        EXPORT To EXCEL
      </a>
      {contactRequests.length === 0 ? (
        <p>No contact requests found.</p>
      ) : (
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              <th className="px-4 py-2 border">Name</th>
              <th className="px-4 py-2 border">Phone</th>
              <th className="px-4 py-2 border">Email</th>
              <th className="px-4 py-2 border">Message</th>
              <th className="px-4 py-2 border">Actions</th>
            </tr>
          </thead>
          <tbody>
            {contactRequests.map((request) => (
              <tr key={request.id}>
                <td className="px-4 py-2 border">{request.name}</td>
                <td className="px-4 py-2 border">{request.phone}</td>
                <td className="px-4 py-2 border">{request.email || "N/A"}</td>
                <td className="px-4 py-2 border">{request.message}</td>
                <td className="px-4 py-2 border">
                  <button
                    onClick={() => handleDelete(request.id)}
                    className="bg-red-500 text-white px-4 py-1 rounded hover:bg-red-600"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AdminContactRequests;
